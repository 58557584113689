import { Component, inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-questionnaire-form',
  templateUrl: './questionnaire-form.component.html',
  styleUrls: ['./questionnaire-form.component.scss'],
})
export class QuestionnaireFormComponent {
  #dialogRef = inject(MatDialogRef);
  #fb = inject(FormBuilder);

  questionAddMode = false;

  questionnaireForm = this.#fb.group({
    title: new FormControl<string>(null, Validators.required),
    description: new FormControl<string>(null, Validators.required),
    questions: this.#fb.array([]) // missing question description here!
  })

  questionForm = this.#fb.array([])


  closeDialog(): void {
    this.#dialogRef.close();
  }

  addQuestion(): void {
    this.questionAddMode = true;
    this.questionForm.clear();
    console.log('question added');
  }

  addRange(): void {
    const questionRange = this.#fb.group({
      rangePercentage: new FormControl<string>(null, Validators.required),
      rangeDescription: new FormControl<string>(null, Validators.required),
    })

    this.questionForm.push(questionRange as any);
  }

  saveQuestion(): void {
    (this.questionnaireForm.controls.questions as FormArray).push(this.questionForm);
    this.questionAddMode = false;
    console.log(this.questionnaireForm.value)
  }
}
