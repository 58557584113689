import { Component, inject, Input, OnInit } from "@angular/core";
import { QuestionGroup } from "../../models/questionnaire";
import { FormGroup, FormGroupDirective } from "@angular/forms";

@Component({
  selector: 'app-questionnarie-radio',
  templateUrl: './questionnarie-radio.component.html',
  styleUrls: ['./questionnarie-radio.component.scss'],
})
export class QuestionnaireRadioComponent implements OnInit {
  @Input() questionGroup!: QuestionGroup;

  #formGroupDirective = inject(FormGroupDirective);

  parentFormGroup!: FormGroup;

  ngOnInit(): void {
    this.parentFormGroup = this.#formGroupDirective.form;
  }
}

// CHOSEN OPTION: [questionId]: possibleAnswerId
