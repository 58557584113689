import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ExternalRedirectGuard implements CanActivate {
  #location = inject(Location);
  #router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (!this.#location.getState()) {
      this.#router.navigate(['not-found']);
    } else {
      window.open(route.data['externalUrl'], '_blank');
    }

    return false;
  }
}
