import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { QuestionnaireListItem, QuestionnaireDetails, QUESTIONNAIRE_STATUS, DIMENSION_TYPE, SelectedAnswer, Question, PossibleAnswer, QUESTIONNAIRE_RESULT_STATUS } from "../models/questionnaire";
import { catchError, switchMap, tap } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { FormBuilder, FormControl } from "@angular/forms";
import { UserValidatorService } from "./user-validator.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class QuestionnaireService {
  #questionnaireList = new BehaviorSubject<QuestionnaireListItem[]>([]);
  #questionnaireDetails = new BehaviorSubject<QuestionnaireDetails>(null);

  isQuestionnaireDataLoading = new BehaviorSubject<boolean>(false);

  readonly #apiPath = environment.apiPath;

  #http = inject(HttpClient);
  #fb = inject(FormBuilder);
  #userValidatorService = inject(UserValidatorService);
  #snackBar = inject(MatSnackBar);

  questionnaireForm = this.#fb.group({});


  get questionnaireList() {
    return this.#questionnaireList;
  }

  get questionnaireDetails() {
    return this.#questionnaireDetails;
  }

  // API METHODS

  fetchQuestionnaireList(teamId?: string): Observable<QuestionnaireListItem[]> {
    const params = new HttpParams({
      fromObject: { teamId }
    });

    return this.#http.get<QuestionnaireListItem[]>(`${this.#apiPath}questionnaire`, teamId && { params }).pipe(tap(res => this.#questionnaireList.next(res)), catchError(() => {
      this.#snackBar.open('Error while fetching questionnaire list', null, { duration: 2500 });
      return of([]);
    }));
  };

  fetchQuestionnaireDetails(id: string, teamId?: string): Observable<QuestionnaireDetails> {
    const params = new HttpParams({
      fromObject: { teamId }
    });

    return this.#http.get<QuestionnaireDetails>(`${this.#apiPath}questionnaire/${id}`, teamId && { params }).pipe(tap(res => this.#questionnaireDetails.next(res)), catchError(() => {
      this.#snackBar.open('Error while fetching questionnaire details', null, { duration: 2500 });
      return of(null);
    }));
  };

  deleteQuestionnaire(id: string): void {
    this.#http.delete(`${this.#apiPath}questionnaire/${id}`).subscribe();
  };

  publishQuestionnaire(questionnaireId: string): void {
    const teamId = this.#userValidatorService.currentUserRights.getValue().currentTeamId;

    this.#http.post<QuestionnaireDetails>(`${this.#apiPath}questionnaire-result`, {
      questionnaireId,
      team: {
        id: teamId,
      }
    }).pipe(tap(res => {
      this.#questionnaireDetails.next(res);

      this.#enableOrDisableQuestionnaireForm(res);

    }), catchError(() => {
      this.#snackBar.open('Error while publishing the questionnaire', null, { duration: 2500 });
      return of(null);
    })).subscribe();
  }

  // loads list of questionnaires and fetches the one from the current year
  loadQuestionnaireData(): void {
    const teamId = this.#userValidatorService.currentUserRights.getValue().currentTeamId;
    const teamRole = this.#userValidatorService.currentUserRights.getValue().currentMember.teamRole.name;

    this.#questionnaireDataCleanup();
    this.isQuestionnaireDataLoading.next(true);

    this.fetchQuestionnaireList(teamRole !== 'ORG_ADMIN' && teamId).pipe(switchMap(list => {

      const activeQuestionnaire = list.find(questionnaire => questionnaire.status === QUESTIONNAIRE_STATUS.ACTIVE);

      if (activeQuestionnaire) {
        return this.fetchQuestionnaireDetails(activeQuestionnaire.id, teamRole !== 'ORG_ADMIN' && teamId).pipe(tap(questionnaireDetails => {
          this.#constructQuestionnaireForm(questionnaireDetails, teamRole === 'TEAM_ADMIN');
          this.#questionnaireDetails.next(questionnaireDetails);
          this.#enableOrDisableQuestionnaireForm(questionnaireDetails);
          this.isQuestionnaireDataLoading.next(false);
        }));
      } else {
        this.isQuestionnaireDataLoading.next(false);
        return of();
      }
    }
    )).subscribe();
  };

  // this method saves the questionnaire answers
  submitQuestionnaire(): Observable<QuestionnaireDetails> {
    const submittionId = this.questionnaireDetails.getValue().results[0].submission.id;
    const selectedAnswers: SelectedAnswer[] = [];

    Object.entries(this.questionnaireForm.value).forEach(singleAnswer => selectedAnswers.push({ answerId: singleAnswer[1] as string, questionId: singleAnswer[0] }));

    return this.#http.put<QuestionnaireDetails>(`${this.#apiPath}questionnaire-submission/${submittionId}`, {
      selectedAnswers
    }).pipe(catchError(() => {
      this.#snackBar.open('Error while submitting the questionnaire', null, { duration: 2500 });
      return of(null);
    }));
  }

  // this method closes the questionnaire
  completeQuestionnaire(): Observable<QuestionnaireDetails> {
    const resultId = this.questionnaireDetails.getValue().results[0].id;

    return this.#http.put<QuestionnaireDetails>(`${this.#apiPath}questionnaire-result/${resultId}/close`, {}).pipe(tap(res => {
      this.questionnaireDetails.next(res);
      this.#enableOrDisableQuestionnaireForm(res);
    }), catchError(() => {
      this.#snackBar.open('Error while completing the questionnaire', null, { duration: 2500 });
      return of(null);
    }));
  }

  // combined submitQuestionnaire() and completeQuestionnaire() as a new requirement for closing the questionnaire
  saveAndCompleteQuestionnaire(): void {
    this.submitQuestionnaire().pipe(switchMap(() => this.completeQuestionnaire())).subscribe();
  }

  #enableOrDisableQuestionnaireForm(questionnaireDetails: QuestionnaireDetails): void {
    if ((questionnaireDetails.status === QUESTIONNAIRE_STATUS.ACTIVE && !questionnaireDetails.results.length) || questionnaireDetails.results[0]?.status === QUESTIONNAIRE_RESULT_STATUS.COMPLETED) {
      // no results, but status active indicate that questionnaire is created but not published
      // published questionnaire will have results
      this.questionnaireForm.disable();
    } else {
      this.questionnaireForm.enable();
    }
  }

  #constructQuestionnaireForm(questionnaireDetails: QuestionnaireDetails, isTeamAdmin: boolean): void {
    const selectedAnswers = questionnaireDetails.results[0]?.submission?.selectedAnswers || [];

    const findSelectedAnswer = (questionId: string): string | null => {
      return selectedAnswers.find(selectedAnswer => selectedAnswer.questionId === questionId)?.answerId || null;
    }

    questionnaireDetails.dimensions.forEach(dimension => dimension.subdimensions.forEach(subdimension => subdimension.questionGroups.forEach(questionGroup => questionGroup.subgroups.forEach(subGroup => subGroup.questions.forEach(question => {
      if (dimension.type === DIMENSION_TYPE.TEAM_ADMIN_ONLY) {
        if (isTeamAdmin) {
          this.questionnaireForm.addControl(question.id, new FormControl(findSelectedAnswer(question.id)));
        } else {
          return;
        }
      } else {
        this.questionnaireForm.addControl(question.id, new FormControl(findSelectedAnswer(question.id)));
      }
    })))));

    this.questionnaireForm.updateValueAndValidity();
  };

  #questionnaireDataCleanup(): void {
    this.#questionnaireDetails.next(null);
    this.#questionnaireList.next([]);

    // remove old controls
    while (Object.keys(this.questionnaireForm.controls).length) {
      const toRemove = Object.keys(this.questionnaireForm.controls)[0];
      this.questionnaireForm.removeControl(toRemove)
    }
  }
}
