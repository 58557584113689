export enum QUESTIONNAIRE_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export enum QUESTIONNAIRE_RESULT_STATUS {
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
}

export enum DIMENSION_TYPE {
  TEAM_ADMIN_ONLY = 'TEAM_ADMIN_ONLY',
  BENCHMARKED = 'BENCHMARKED'
}

export interface QuestionnaireListItem {
  id: string;
  title: string;
  description: string;
  publishedOn: Date;
  closedOn: Date;
  status: QUESTIONNAIRE_STATUS;
  results: Result[];
}

export interface QuestionnaireDetails {
  id: string;
  title: string;
  description: string;
  year: number;
  publishedOn: Date;
  status: QUESTIONNAIRE_STATUS;
  dimensions: Dimension[];
  results: DetailsResult[];
}

export interface Question {
  id: string;
  title: string;
  description: string;
  norm: number;
  ordinal: number;
}

export interface SelectedAnswer {
  questionId: string
  answerId: string | null,
}

interface Result {
  id: string;
  startedOn: Date;
  completedOn: Date;
  status: QUESTIONNAIRE_RESULT_STATUS;
  team: Team;
  auditedOn: string;
  auditor: Auditor;
  submissionsCount: number;
  submission?: {
    id: string,
    selectedAnswers: SelectedAnswer[];
  }
}

interface Auditor {
  id: string;
  memberName: string;
  archived: boolean;
  user: User;
  team: Team;
}

interface Team {
  id: string;
  name: string;
  group: string;
  sequence: number;
  teamGroupId: string;
}

interface User {
  id: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  active: boolean;
  organisationAdmin: boolean;
}

interface Dimension {
  id: string;
  title: string;
  description: string;
  ordinal: number;
  type: DIMENSION_TYPE;
  subdimensions: Subdimension[];
}

interface Subdimension {
  id: string;
  title: string;
  description: string;
  ordinal: number;
  questionGroups: QuestionGroup[];
}

export interface PossibleAnswer {
  id: string;
  shortExplanation: string;
  value: number;
}


export interface QuestionGroup {
  id: string;
  ordinal: number;
  norm: number;
  subgroups: QuestionGroupSubGroup[];
  possibleAnswers: PossibleAnswer[];
}

interface QuestionGroupSubGroup {
  id: string;
  title: string;
  ordinal: number;
  questions: Question[];
}

interface DetailsResult {
  id: string;
  startedOn: Date;
  completedOn: Date;
  status: QUESTIONNAIRE_RESULT_STATUS;
  team: Team;
  auditedOn: string;
  auditor: Auditor;
  audit: Audit[];
  submissionsCount: number;
  averageDimensionsScores: Audit[];
  submission?: {
    id: string,
    selectedAnswers: SelectedAnswer[];
  }
}

interface Audit {
  dimensionId: string;
  norm: number;
  value: number;
  status: string;
  questionScores: QuestionScore[];
}

interface QuestionScore {
  value: number;
  norm: number;
  questionId: string;
  status: string;
}
