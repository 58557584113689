/** @format */

import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, Injectable } from '@angular/core';
import { FlexLayoutModule, BREAKPOINTS } from '@angular/flex-layout';
import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';

import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import {
  CoreModule,
  LoaderService,
  SnackbarService,
  LayoutService,
} from '@iq/ng-core';
import { AuthModule, malAuthService, malTokenInterceptor } from '@iq/ng-auth';
import { CommonModule, registerLocaleData } from '@angular/common';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpModule } from '@angular/http';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxSummernoteModule } from 'ngx-summernote';
import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Modules **/
import { KmsRouteModule } from './auth.module';

/** Containers **/
import { OverviewComponent } from './containers/overview/overview.component';
import { TheLampComponent } from './containers/the-lamp/the-lamp.component';
import { UsersComponent } from './containers/users/users.component';
import { MainContainerComponent } from './containers/main-container/main-container.component';
import { ImprovementPlanComponent } from './containers/improvement-plan/improvement-plan.component';
import { BestPracticesComponent } from './containers/best-practices/best-practices.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { ActionsListComponent } from './containers/actions-list/actions-list.component';
import { IncidentsComponent } from './containers/incidents/incidents.component';
import { AgendaComponent } from './containers/agenda/agenda.component';
import { YearPlanningComponent } from './containers/year-planning/year-planning.component';
import { ImprovementsComponent } from './containers/improvements/improvements.component';
import { FilesLibraryComponent } from './containers/files-library/files-library.component';
import { PmrComponent } from './containers/pmr/pmr.component';
import { QuestionnaireComponent } from './containers/questionnaire/questionnaire.component';

const CONTAINERS = [
  OverviewComponent,
  TheLampComponent,
  UsersComponent,
  MainContainerComponent,
  ImprovementPlanComponent,
  BestPracticesComponent,
  DashboardComponent,
  ActionsListComponent,
  IncidentsComponent,
  AgendaComponent,
  YearPlanningComponent,
  ImprovementsComponent,
  FilesLibraryComponent,
  PmrComponent,
  GraphsComponent,
  QuestionnaireComponent,
];

/** Dialogs **/
import { ImprGroupDetailsComponent } from './dialogs/impr-group-details/impr-group-details.component';
import { WarningDeleteDialogComponent } from './dialogs/warning-delete-dialog/warning-delete-dialog.component';
import { AddBestPracticeComponent } from './dialogs/add-best-practice/add-best-practice.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { AddEditTasksTemplateComponent } from './dialogs/add-edit-tasks-template/add-edit-tasks-template.component';
import { AddEditTasksTemplateEmbeddedComponent } from './dialogs/add-edit-tasks-template/add-edit-tasks-template-embedded.component';
import { AddEditTaskComponent } from './dialogs/add-edit-task/add-edit-task.component';
import { AddEditUserComponent } from './dialogs/add-edit-user/add-edit-user.component';
import { AddEditTeamComponent } from './dialogs/add-edit-team/add-edit-team.component';
import { AddEditTeamGroupComponent } from './dialogs/add-edit-team-group/add-edit-team-group.component';
import { AddEditIncidentComponent } from './dialogs/add-edit-incident/add-edit-incident.component';
import { AddEditAgendaMeetingComponent } from './dialogs/add-edit-agenda-meeting/add-edit-agenda-meeting.component';
import { AddEditAgendaTemplateComponent } from './dialogs/add-edit-agenda-template/add-edit-agenda-template.component';
import { AddEditSubthemeOwnershipComponent } from './dialogs/add-edit-subtheme-ownership/add-edit-subtheme-ownership.component';
import { AddEditImprovementItemComponent } from './dialogs/add-edit-improvement-item/add-edit-improvement-item.component';
import { AddEditImprovementComponent } from './dialogs/add-edit-improvement/add-edit-improvement.component';
import { AddEditFileComponent } from './dialogs/add-edit-file/add-edit-file.component';
import { AddEditYearEventComponent } from './dialogs/add-edit-year-event/add-edit-year-event.component';
import { AddEditPmrItemComponent } from './dialogs/add-edit-pmr-item/add-edit-pmr-item.component';
import { AddEditPmrComponent } from './dialogs/add-edit-pmr/add-edit-pmr.component';
import { AddEditDefaultPmrItemComponent } from './dialogs/add-edit-default-pmr-item/add-edit-default-pmr-item.component';
import { AddEditIncidentAnalysisComponent } from './dialogs/add-edit-incident-analysis/add-edit-incident-analysis.component';
import { AddEditImprovementAnalysisComponent } from './dialogs/add-edit-improvement-analysis/add-edit-improvement-analysis.component';

const DIALOGS = [
  ImprGroupDetailsComponent,
  WarningDeleteDialogComponent,
  AddBestPracticeComponent,
  ErrorDialogComponent,
  AddEditTasksTemplateComponent,
  AddEditTasksTemplateEmbeddedComponent,
  AddEditTaskComponent,
  AddEditUserComponent,
  AddEditTeamComponent,
  AddEditTeamGroupComponent,
  AddEditIncidentComponent,
  AddEditAgendaMeetingComponent,
  AddEditAgendaTemplateComponent,
  AddEditSubthemeOwnershipComponent,
  AddEditImprovementItemComponent,
  AddEditImprovementComponent,
  AddEditFileComponent,
  AddEditYearEventComponent,
  AddEditPmrItemComponent,
  AddEditPmrComponent,
  AddEditDefaultPmrItemComponent,
  AddEditIncidentAnalysisComponent,
  AddEditImprovementAnalysisComponent,
];

/** Services **/
import { LampService } from './services/lamp.service';
import { ScoreItemsListService } from './services/score-items-list.service';
import { UserValidatorService } from './services/user-validator.service';
import { TeamGroupsListService } from './services/team-groups-list.service';
import { TeamsListService } from './services/teams-list.service';
import { TheLampResolverService } from './services/the-lamp-resolver.service';
import { UsersListService } from './services/users-list.service';
import { RolesService } from './services/roles.service';
import { MembersService } from './services/members.service';
import { SharedService } from './services/shared.service';
import { ImprovementGroupService } from './services/improvement-group.service';
import { ImprovementItemsService } from './services/improvement-items.service';
import { BestPracticesService } from './services/best-practices.service';
import { BestPracticeLikeService } from './services/bestpractice-like.service';
import { TasksTemplateService } from './services/tasks-template.service';
import { OverviewService } from './services/overview.service';
import { SubthemeOwnershipService } from './services/subtheme-ownership.service';
import { RecurringTasksService } from './services/recurring-tasks.service';
import { MenuTogglerService } from './services/menu-toggler.service';
import { IncidentsService } from './services/incidents.service';
import { ImprovementsService } from './services/improvements.service';
import { YearlyPlannedSubjectsService } from './services/yearly-planned-subjects.service';
import { MeetingsService } from './services/meetings.service';
import { YearPlanningService } from './services/year-planning.service';
import { YearPlanningCategoriesService } from './services/year-planning-categories.service';
import { AgendaTemplatesService } from './services/agenda-templates.service';
import { ExecutionersService } from './services/executioners.service';
import { FilesLibraryService } from './services/files-library.service';
import { DocumentsService } from './services/documents.service';
import { PmrItemsDefaultService } from './services/pmr-items-default.service';
import { PmrService } from './services/pmr.service';
import { PmrItemsService } from './services/pmr-items.service';
import { IncidentAnalysisService } from './services/incident-analysis.service';
import { ImprovementAnalysisService } from './services/improvement-analysis.service';
import { DashboardService } from './services/dashboard.service';
import { PmrItemsCoService } from './services/pmr-items-co.service';
import { PracticeChangeService } from './services/practice-change.service';
import { QuestionnaireService } from './services/questionnaire.service';
import { PracticeInfoService } from './services/practice-info.service';

export const SERVICES = [
  CacheUpdateService,
  LampService,
  ScoreItemsListService,
  UserValidatorService,
  TeamGroupsListService,
  TeamsListService,
  TheLampResolverService,
  UsersListService,
  RolesService,
  MembersService,
  SharedService,
  ImprovementGroupService,
  ImprovementItemsService,
  BestPracticesService,
  BestPracticeLikeService,
  TasksTemplateService,
  OverviewService,
  SubthemeOwnershipService,
  RecurringTasksService,
  MenuTogglerService,
  IncidentsService,
  ImprovementsService,
  YearlyPlannedSubjectsService,
  MeetingsService,
  YearPlanningService,
  YearPlanningCategoriesService,
  AgendaTemplatesService,
  ExecutionersService,
  FilesLibraryService,
  DocumentsService,
  PmrItemsDefaultService,
  PmrService,
  PmrItemsService,
  IncidentAnalysisService,
  ImprovementAnalysisService,
  DashboardService,
  PmrItemsCoService,
  SunburstGraphService,
  PracticeChangeService,
  QuestionnaireService,
  PracticeInfoService
];

/** Guards **/
import { AuthGuard } from './guards/auth.guard';
import { TeamIdGuard } from './guards/teamId.guard';
import { NoRightsGuard } from './guards/no-rights.guard';
import { GraphsGuard } from './guards/graphs.guard';
import { ImprovementReviewGuard } from './guards/improvement-review.guard';
import { ExternalRedirectGuard } from './guards/external-redirect.guard';

/** Functions **/
import { getDutchPaginatorIntl } from './functions/dutch-paginator';

/** Components **/
import { AppComponent } from './app.component';
import { ThemeComponent } from './components/theme/theme.component';
import { ScoreItemsListComponent } from './components/score-items-list/score-items-list.component';
import { ScoreItemsListItemComponent } from './components/score-items-list-item/score-items-list-item.component';
import { UsersManagementComponent } from './components/users-management/users-management.component';
import { TeamsManagementComponent } from './components/teams-management/teams-management.component';
import { TeamGroupsListComponent } from './components/team-groups-list/team-groups-list.component';
import { TeamsListComponent } from './components/teams-list/teams-list.component';
import { ChooseTeamPanelComponent } from './components/choose-team-panel/choose-team-panel.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { ImprovementPlanTabComponent } from './components/improvement-plan-tab/improvement-plan-tab.component';
import { ImprovementGroupComponent } from './components/improvement-group/improvement-group.component';
import { ImprovementItemComponent } from './components/improvement-item/improvement-item.component';
import { BestPracticesListComponent } from './components/best-practices-list/best-practices-list.component';
import { TeamGroupsTileListComponent } from './components/team-groups-tile-list/team-groups-tile-list.component';
import { ThemesTileListComponent } from './components/themes-tile-list/themes-tile-list.component';
import { OverviewContentComponent } from './components/overview-content/overview-content.component';
import { OverviewAverageScoresBlockComponent } from './components/overview-average-scores-block/overview-average-scores-block.component';
import { OverviewScoreItemTileComponent } from './components/overview-score-item-tile/overview-score-item-tile.component';
import { TasksManagementComponent } from './components/tasks-management/tasks-management.component';
import { TasksOverviewComponent } from './components/tasks-overview/tasks-overview.component';
import { TasksTemplatesListComponent } from './components/tasks-templates-list/tasks-templates-list.component';
import { TasksGroupContainerComponent } from './components/tasks-group-container/tasks-group-container.component';
import { TaskTileComponent } from './components/task-tile/task-tile.component';
import { SubthemeOwnersListComponent } from './components/subtheme-owners-list/subtheme-owners-list.component';
import { SubthemeOwnersManagementComponent } from './components/subtheme-owners-management/subtheme-owners-management.component';
import { DashboardViewComponent } from './components/dashboard-view/dashboard-view.component';
import { DashboardTasksContainerComponent } from './components/dashboard-tasks-container/dashboard-tasks-container.component';
import { IncidentsListComponent } from './components/incidents-list/incidents-list.component';
import { ImprovementsListComponent } from './components/improvements-list/improvements-list.component';
import { ExecutionerComponent } from './components/executioner/executioner.component';
import { AgendaTemplatesComponent } from './components/agenda-templates/agenda-templates.component';
import { AgendaMeetingsComponent } from './components/agenda-meetings/agenda-meetings.component';
import { AgendaTemplatesListComponent } from './components/agenda-templates-list/agenda-templates-list.component';
import { AgendaSubjectComponent } from './components/agenda-subject/agenda-subject.component';
import { AgendaMeetingsListComponent } from './components/agenda-meetings-list/agenda-meetings-list.component';
import { MeetingMembersListComponent } from './components/meeting-members-list/meeting-members-list.component';
import { YearPlanningDashboardComponent } from './components/year-planning-dashboard/year-planning-dashboard.component';
import { ImprovementsListEmbeddedComponent } from './components/improvements-list-embedded/improvements-list-embedded.component';
import { FilesListComponent } from './components/files-list/files-list.component';
import { PmrDefaultItemsComponent } from './components/pmr-default-items/pmr-default-items.component';
import { PmrTabComponent } from './components/pmr-tab/pmr-tab.component';
import { PmrListComponent } from './components/pmr-list/pmr-list.component';
import { PmrItemsListComponent } from './components/pmr-items-list/pmr-items-list.component';
import { PmrImprovementsListEmbeddedComponent } from './components/pmr-improvements-list-embedded/pmr-improvements-list-embedded.component';
import { NotRightsPageComponent } from './components/not-rights-page/not-rights-page.component';
import { AttachmentsListEmbeddedComponent } from './components/attachments-list-embedded/attachments-list-embedded.component';
import { PlannedSessionComponent } from './components/planned-session/planned-session.component';
import { IncidentAnalysisListComponent } from './components/incident-analysis-list/incident-analysis-list.component';
import { ImprovementAnalysisListComponent } from './components/improvement-analysis-list/improvement-analysis-list.component';
import { DashboardSingleCardComponent } from './components/dashboard-single-card/dashboard-single-card.component';
import { PmrItemsCoOverviewComponent } from './components/pmr-items-co-overview/pmr-items-co-overview.component';
import { SunburstGraphComponent } from './components/sunburst-graph/sunburst-graph.component';
import { GraphDetailsComponent } from './components/graph-details/graph-details.component';
import { GraphListComponent } from './components/graph-list/graph-list.component';
import { PracticeSelectDropdownComponent } from './components/practice-select-dropdown/practice-select-dropdown.component';
import { SunburstGraphTooltipComponent } from './components/sunburst-graph-tooltip/sunburst-graph-tooltip.component';
import { NavUserDropdownComponent } from './components/nav-user-dropdown/nav-user-dropdown.component';
import { GraphContactFormComponent } from './components/graph-contact-form/graph-contact-form.component';
import { YearPlanningDropdownComponent } from './components/year-planning-dropdown/year-planning-dropdown.component';
import { NgxPrintModule } from 'ngx-print';
import { QuestionnaireRadioComponent } from './components/questionnarie-radio/questionnarie-radio.component';
import { QuestionnaireFormComponent } from './components/questionnaire-form/questionnaire-form.component';
import { BlankComponent } from './containers/blank/blank.component';
import { PracticeInfoComponent } from './components/practice-info/practice-info.component';

const COMPONENTS = [
  AppComponent,
  ThemeComponent,
  ScoreItemsListComponent,
  ScoreItemsListItemComponent,
  UsersManagementComponent,
  TeamsManagementComponent,
  TeamGroupsListComponent,
  TeamsListComponent,
  ChooseTeamPanelComponent,
  UsersListComponent,
  ImprovementPlanTabComponent,
  ImprovementGroupComponent,
  ImprovementItemComponent,
  BestPracticesListComponent,
  TeamGroupsTileListComponent,
  ThemesTileListComponent,
  OverviewContentComponent,
  OverviewAverageScoresBlockComponent,
  OverviewScoreItemTileComponent,
  TasksManagementComponent,
  TasksOverviewComponent,
  TasksTemplatesListComponent,
  TasksGroupContainerComponent,
  TaskTileComponent,
  SubthemeOwnersListComponent,
  SubthemeOwnersManagementComponent,
  DashboardViewComponent,
  DashboardTasksContainerComponent,
  IncidentsListComponent,
  ImprovementsListComponent,
  ExecutionerComponent,
  AgendaTemplatesComponent,
  AgendaMeetingsComponent,
  AgendaTemplatesListComponent,
  AgendaSubjectComponent,
  AgendaMeetingsListComponent,
  MeetingMembersListComponent,
  YearPlanningDashboardComponent,
  ImprovementsListEmbeddedComponent,
  FilesListComponent,
  PmrDefaultItemsComponent,
  PmrTabComponent,
  PmrListComponent,
  PmrItemsListComponent,
  PmrImprovementsListEmbeddedComponent,
  NotRightsPageComponent,
  AttachmentsListEmbeddedComponent,
  PlannedSessionComponent,
  IncidentAnalysisListComponent,
  ImprovementAnalysisListComponent,
  DashboardSingleCardComponent,
  PmrItemsCoOverviewComponent,
  SunburstGraphComponent,
  GraphDetailsComponent,
  GraphListComponent,
  NavUserDropdownComponent,
  SunburstGraphTooltipComponent,
  PracticeSelectDropdownComponent,
  GraphContactFormComponent,
  YearPlanningDropdownComponent,
  QuestionnaireRadioComponent,
  QuestionnaireFormComponent,
  BlankComponent,
  PracticeInfoComponent
];

/** Pipes **/
import { FileSizePipe } from './pipes/file-size.pipe';
import { CacheUpdateService } from './services/cache-update.service';
import { safariDateFormatPipe } from './pipes/safari-date-format.pipe';
import { SunburstGraphService } from './services/sunburst-graph.service';
import { GraphsComponent } from './containers/graphs/graphs.component';

const PIPES = [FileSizePipe, safariDateFormatPipe];

const MAT_MODULES = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
];

const MOBILE_BREAKPOINTS = [
  {
    alias: 'cmb',
    suffix: 'cmb',
    mediaQuery: '( max-width: 1062px )',
    overlapping: false,
  },
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINTS,
  useValue: [...MOBILE_BREAKPOINTS],
};

//  Register the NL locale data
//
registerLocaleData(localeNl, 'nl', localeNlExtra);

//  Date picker formats
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

//  Hammer custom config

// declare var Hammer:     any;

// ! test, hammer no more used - could not find so far any mobile app logic
// /**
//  *  Custom config for the HammerJS
//  *
//  * @export
//  * @class MyHammerConfig
//  * @extends {HammerGestureConfig}
//  */
// @Injectable()
// export class MyHammerConfig extends HammerGestureConfig {

//   /**
//    * Build the hammer instance
//    *
//    * @param {HTMLElement} element
//    * @returns
//    * @memberof MyHammerConfig
//    */
//   buildHammer( element: HTMLElement ) {

//     const mc = new Hammer( element, {

//       touchAction: 'pan-y'
//     });

//     return mc;
//   }

// }
PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [CONTAINERS, DIALOGS, COMPONENTS, PIPES],
  imports: [
    MAT_MODULES,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    HttpClientModule,
    // HttpModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    PlotlyModule,
    NgxPrintModule,
    DragDropModule,
    NgxMaterialTimepickerModule,
    NgxSummernoteModule,
    MatDatepickerModule,
    MatMomentDateModule,
    KmsRouteModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    // AuthModule
    AuthModule.forRoot({
      //! check, it was not working
      userName: null,
      apiUrl: null,
      apiPath: '/api',
      forgotPassword: '/forgotpassword',
      setPassword: '/users/activation',
      login: '/token',
      refreshToken: '/refresh',
      user: '/users/identity',
    }),
  ],
  providers: [
    CustomBreakPointsProvider,
    malAuthService,
    LoaderService,
    SnackbarService,
    LayoutService,
    MenuTogglerService,
    { provide: LOCALE_ID, useValue: 'nl' },
    SERVICES,
    AuthGuard,
    TeamIdGuard,
    NoRightsGuard,
    GraphsGuard,
    ExternalRedirectGuard,
    ImprovementReviewGuard,
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    { provide: HTTP_INTERCEPTORS, useClass: malTokenInterceptor, multi: true },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }, // ! test
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
