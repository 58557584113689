import { Role } from "./role";

export interface PraciceInfoFromLocalStorage {
  name: string;
  agbCode: string;
  emailAddress: string;
  accountType: PracticeInfoAccountType; // WILL BE A DROPDOWN LATER
  legalForm: string;
  insuranceCompaniesContracted: Array<InsuranceCompanyContracted>;
  numberOfEmployees: number;
  network: string;
  practiceRegister: string;
  professionalAssociation: string;
  address: {
    street: string;
    postalCode: string;
    region: string;
    city: string;
  };
}

export interface PraciceInfoToSend extends Omit<PraciceInfoFromLocalStorage, 'insuranceCompaniesContracted'> {
  insuranceCompaniesContracted: Array<Pick<InsuranceCompanyContracted, 'id'>>;
}


export interface PracticeInfoForm extends Omit<PraciceInfoFromLocalStorage, 'address'> {
  street: string;
  postalCode: string;
  region: string;
  city: string;
}

export enum PracticeInfoAccountType {
  BASIC = 'BASIC',
  EXTENDED = 'EXTENDED',
}

export interface InsuranceCompanyContracted {
  id: string;
  name: string;
}

export interface NewPraciceInfo {
  accountType: PracticeInfoAccountType; // WILL BE A DROPDOWN LATER
  address: {
    street: string;
    postalCode: string;
    region: string;
    city: string;
  };
  allowedRoles?: Omit<Role, 'permissions'>[];
  emailAddress: string;
  group: string;
  id: string;
  insuranceCompaniesContracted: Array<InsuranceCompanyContracted>;
  legalForm: string;
  name: string;
  network: string;
  numberOfEmployees: number;
  sequence: number;
  teamGroupId: string;
}
