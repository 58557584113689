/** @format */

import { AfterViewInit, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { PracticeInfoService } from '../../services/practice-info.service';

@Component({
  selector: 'app-practice-info',
  templateUrl: './practice-info.component.html',
  styleUrls: ['./practice-info.component.scss'],
})
export class PracticeInfoComponent implements OnInit, AfterViewInit {
  #practiceInfoService = inject(PracticeInfoService);
  #cdr = inject(ChangeDetectorRef);

  insuranceCompanies$ = this.#practiceInfoService.insuranceCompanies$;

  practiceInfoForm = this.#practiceInfoService.practiceInfoForm

  ngOnInit(): void {
    this.#practiceInfoService.fetchListOfInsuranceCompanies().subscribe(() => {
      this.#practiceInfoService.mapFieldsFromStorageToTheForm();
    }
    )
  };

  ngAfterViewInit(): void {
    this.#cdr.detectChanges();
  }

  saveForm(): void {
    this.#practiceInfoService.updateForm();
  }
}
