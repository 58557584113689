
/** @format */

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UserValidatorService } from '../../services/user-validator.service';
import { DIMENSION_TYPE, QUESTIONNAIRE_RESULT_STATUS, QUESTIONNAIRE_STATUS, QuestionnaireDetails } from '../../models/questionnaire';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  isUserTeamAdmin: boolean;
  isUserCoAdmin: boolean;
  isQuestionnaireNotStartedYet: boolean;
  isQuestionnaireCompleted: boolean; // was opened, filled out, saved and finally closed now

  #destroySubject = new Subject<void>();
  #questionnaireService = inject(QuestionnaireService);
  #userValidatorService = inject(UserValidatorService);

  questionnaireDetails$: BehaviorSubject<QuestionnaireDetails>;
  questionnaireLoading$: BehaviorSubject<boolean>;

  readonly questionnaireForm = this.#questionnaireService.questionnaireForm;
  readonly DIMENSION_TYPE = DIMENSION_TYPE;

  ngOnInit(): void {
    this.getQuestionnarieDetails();

    this.questionnaireDetails$ = this.#questionnaireService.questionnaireDetails;
    this.questionnaireLoading$ = this.#questionnaireService.isQuestionnaireDataLoading;

    this.isUserTeamAdmin = this.#userValidatorService.currentUserRights.getValue().currentMember.teamRole.name === 'TEAM_ADMIN';
    this.isUserCoAdmin = this.#userValidatorService.currentUserRights.getValue().currentMember.teamRole.name === 'ORG_ADMIN';
    this.saveFormOnAnyChange();

    this.questionnaireDetails$.pipe(takeUntil(this.#destroySubject), tap(val => {
      if (val) {
        this.isQuestionnaireNotStartedYet = !val.results.length && val.status === QUESTIONNAIRE_STATUS.ACTIVE;
        this.isQuestionnaireCompleted = val.results[0]?.status === QUESTIONNAIRE_RESULT_STATUS.COMPLETED;
      }
    })).subscribe();

    this.questionnaireForm.markAsPristine();
  }

  saveFormOnAnyChange(): void {
    this.questionnaireForm.valueChanges.pipe(takeUntil(this.#destroySubject)).subscribe(val => {
      if (this.questionnaireForm.dirty) {
        this.saveQuestionaire();
      }
    })
  }

  getQuestionnarieDetails(): void {
    this.#questionnaireService.loadQuestionnaireData();
  }

  publishQuestionaire(): void {
    this.#questionnaireService.publishQuestionnaire(this.questionnaireDetails$.getValue().id);
  }

  completeQuestionaire(): void {
    this.#questionnaireService.saveAndCompleteQuestionnaire();
    this.questionnaireForm.markAsUntouched();
  }

  saveQuestionaire(): void {
    this.#questionnaireService.submitQuestionnaire().subscribe();
  }

  ngOnDestroy(): void {
    this.#destroySubject.next();
    this.#destroySubject.complete();
  }
}
