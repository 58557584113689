/** @format */

import { Component } from '@angular/core';

// This is a blank component for Handboek external link handling purpose

@Component({
  template: ''
})
export class BlankComponent { };
