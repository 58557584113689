<!-- @format -->

<h2
  mat-dialog-title
  i18n="add-edit-team-dialog dialog-title"
  *ngIf="formIsNew"
>
  Nieuwe praktijk toevoegen
</h2>
<h2
  mat-dialog-title
  i18n="add-edit-team-dialog dialog-title"
  *ngIf="!formIsNew"
>
  Wijzigen praktijk
</h2>

<mat-dialog-content *ngIf="initialFormValues">
  <div class="team-name">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Praktijknaam</mat-label>
        <input
          type="text"
          matInput
          formControlName="name"
          required
        />
        <mat-error [hidden]="!formErrors.name">{{ formErrors.name }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="team-group">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Praktijkgroep</mat-label>
        <mat-select
          formControlName="teamGroupId"
          [compareWith]="compareFn"
        >
          <mat-option
            *ngFor="let group of currentTeamGroups"
            [value]="group.id"
            (click)="onTeamGroupSelect(group)"
          >
            {{ group.name }}
          </mat-option>
        </mat-select>
        <mat-error [hidden]="!formErrors.teamGroupId">{{
          formErrors.teamGroupId
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="team-group">
    <form [formGroup]="newFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Account Type</mat-label>
        <mat-select
          formControlName="accountType"
          [compareWith]="compareFn"
        >
          <mat-option
            *ngFor="let accountType of accountTypes"
            [value]="accountType.value"
            (click)="onAccountTypeSelect(accountType)"
            [disabled]="accountType.value === 'EXTENDED'"
          >
            {{ accountType.name }}
          </mat-option>
        </mat-select>
        <mat-error [hidden]="!formErrors.accountType">{{
          formErrors.accountType
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="onCancel()"
    i18n="cancel button @@buttonCancel"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="!formSubmitted"
    (click)="onSubmit(null, null, null, true)"
    [disabled]="!formChanged || newFormGroup.invalid"
    i18n="save button @@buttonSave"
  >
    Opslaan
  </button>
  <button
    mat-flat-button
    color="accent"
    *ngIf="formSubmitted"
    class="submitted"
    i18n="saved button @@buttonSaved"
  >
    <mat-icon>check</mat-icon> Opgeslagen
  </button>
  <button
    mat-icon-button
    mat-dialog-close
    class="dialog-action-close"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-dialog-actions>
