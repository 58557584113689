<!-- @format -->

<h1
  class="mat-h1"
  i18n="dashboard page-title"
>
  IPF vragenlijst
</h1>

<section class="main-content change-on-mobile">
  <ng-container
    *ngIf="
      questionnaireLoading$ | async;
      then loading;
      else questionnaireContainer
    "
  ></ng-container>
</section>

<ng-template #questionnaireContainer>
  <ng-container
    *ngIf="
      (questionnaireDetails$ | async) && !isUserCoAdmin;
      then questionnaire;
      else noData
    "
  ></ng-container>
</ng-template>

<ng-template #questionnaire>
  <section
    *ngIf="!(questionnaireLoading$ | async)"
    [formGroup]="questionnaireForm"
  >
    <section class="questionnaire__menu">
      <div>
        <h4>Vragenlijst</h4>
      </div>
      <div></div>
      <div class="questionnaire__menu__buttons">
        <button
          *ngIf="
            isUserTeamAdmin &&
            isQuestionnaireNotStartedYet &&
            !isQuestionnaireCompleted
          "
          mat-flat-button
          color="accent"
          class="no-grow"
          (click)="publishQuestionaire()"
          i18n="tasks-templates-list button add-new-cyclic-task"
        >
          <mat-icon>people</mat-icon>
          Publish questionnaire
        </button>
        <button
          mat-flat-button
          color="accent"
          class="no-grow"
          (click)="completeQuestionaire()"
          i18n="tasks-templates-list button add-new-cyclic-task"
          *ngIf="
            isUserTeamAdmin &&
            !isQuestionnaireNotStartedYet &&
            !isQuestionnaireCompleted
          "
        >
          <mat-icon>check</mat-icon>
          Complete questionnaire
        </button>

        <!-- temporary commented out save button due to dynamic form saving on any form change -->
        <!-- <button
          mat-flat-button
          color="accent"
          class="no-grow"
          (click)="saveQuestionaire()"
          i18n="tasks-templates-list button add-new-cyclic-task"
          *ngIf="!isQuestionnaireNotStartedYet && !isQuestionnaireCompleted"
        >
          <mat-icon>save</mat-icon>
          Save questionnaire
        </button> -->
      </div>
    </section>

    <section *ngIf="questionnaireDetails$ | async">
      <div class="questionnaire__section">
        <h4>{{ (questionnaireDetails$ | async).title }}</h4>
        <h5>{{ (questionnaireDetails$ | async).description }}</h5>
      </div>

      <ng-container
        *ngFor="let dimension of (questionnaireDetails$ | async).dimensions"
      >
        <div
          *ngIf="
            (dimension.type === DIMENSION_TYPE.TEAM_ADMIN_ONLY &&
              isUserTeamAdmin) ||
            dimension.type !== DIMENSION_TYPE.TEAM_ADMIN_ONLY
          "
        >
          <h4>{{ dimension.title }}</h4>
          <div
            *ngFor="let subdimension of dimension.subdimensions"
            class="questionnaire__section"
          >
            <h4>{{ subdimension.title }}</h4>
            <ng-container
              *ngFor="let singleQuestionGroup of subdimension.questionGroups"
            >
              <app-questionnarie-radio
                [questionGroup]="singleQuestionGroup"
              ></app-questionnarie-radio>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </section>
  </section>
</ng-template>

<ng-template #loading>
  <h2>Loading questionnaire data...</h2>
</ng-template>

<ng-template #noData>
  <h2>No questionnaire to be shown</h2>
</ng-template>
