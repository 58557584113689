<!-- @format -->

<section>
  <ng-container
    *ngTemplateOutlet="
      questionGroup.subgroups.length === 1 &&
      questionGroup.subgroups[0].questions.length === 1
        ? singleQuestion
        : groupedQuestions;
      context: { questionGroup }
    "
  ></ng-container>
</section>

<ng-template
  #singleQuestion
  let-questionGroup="questionGroup"
  [formGroup]="parentFormGroup"
>
  <h5>{{ questionGroup.subgroups[0].questions[0].title }}</h5>

  <div class="questionnarie-radio__button-wrapper">
    <div class="questionnarie-radio__button">
      <div>
        <div class="questionnarie-radio__button__heading">
          <span
            class="questionnarie-radio__button__heading__label"
            *ngFor="let possibleAnswer of questionGroup.possibleAnswers"
            >{{ possibleAnswer.shortExplanation }}</span
          >
        </div>
        <mat-radio-group
          class="questionnarie-radio__button__radio"
          [formControlName]="questionGroup.subgroups[0].questions[0].id"
        >
          <mat-radio-button
            *ngFor="let possibleAnswer of questionGroup.possibleAnswers"
            [value]="possibleAnswer.id"
          ></mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="questionnarie-radio__description">
      <span>{{ questionGroup.subgroups[0].questions[0].description }}</span>
    </div>
  </div>
</ng-template>

<ng-template
  #groupedQuestions
  let-questionGroup="questionGroup"
  [formGroup]="parentFormGroup"
>
  <div class="question-group__heading__container">
    <div class="question-group__heading">
      <div class="questionnarie-radio__button__heading">
        <span
          class="questionnarie-radio__button__heading__label"
          *ngFor="let possibleAnswer of questionGroup.possibleAnswers"
          >{{ possibleAnswer.shortExplanation }}</span
        >
      </div>
    </div>
  </div>

  <div *ngFor="let subgroup of questionGroup.subgroups">
    <p class="question-group__subgroup__separator">
      {{ subgroup.title || '' }}
    </p>
    <div
      *ngFor="let question of subgroup.questions"
      class="question-group__question__container"
    >
      <span class="question-group__question__description">
        {{ question.title }}
      </span>

      <mat-radio-group
        class="questionnarie-radio__button__radio question-group__question__radio"
        [formControlName]="question.id"
      >
        <mat-radio-button
          *ngFor="let possibleAnswer of questionGroup.possibleAnswers"
          [value]="possibleAnswer.id"
        ></mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</ng-template>
