<!-- @format -->
<div
  class="practice-info__container"
  [formGroup]="practiceInfoForm"
>
  <mat-form-field appearance="fill">
    <mat-label>Naam</mat-label>
    <input
      type="text"
      matInput
      readonly
      formControlName="name"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>E-mailadres</mat-label>
    <input
      type="text"
      matInput
      formControlName="emailAddress"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Postcode</mat-label>
    <input
      type="text"
      matInput
      formControlName="postalCode"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Aantal FTE</mat-label>
    <input
      type="text"
      matInput
      formControlName="numberOfEmployees"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Rechtvorm</mat-label>
    <input
      type="text"
      matInput
      formControlName="legalForm"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Beroepsvereniging</mat-label>
    <input
      type="text"
      matInput
      formControlName="professionalAssociation"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Woonplaats</mat-label>
    <input
      type="text"
      matInput
      formControlName="city"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Adres</mat-label>
    <input
      type="text"
      matInput
      formControlName="street"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Praktijkregister</mat-label>
    <input
      type="text"
      matInput
      formControlName="practiceRegister"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Netwerk</mat-label>
    <input
      type="text"
      matInput
      formControlName="network"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Praktijk AGB-Code</mat-label>
    <input
      type="text"
      matInput
      formControlName="agbCode"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Region Name</mat-label>
    <input
      type="text"
      matInput
      formControlName="region"
    />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Gecontracteerde Zorgverzekeraars</mat-label>
    <mat-select
      multiple
      formControlName="insuranceCompaniesContracted"
    >
      <mat-option value="">---</mat-option>
      <mat-option
        *ngFor="let insuranceCompany of insuranceCompanies$ | async"
        [value]="insuranceCompany.id"
      >
        {{ insuranceCompany.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Account Type</mat-label>
    <input
      type="text"
      readonly="true"
      matInput
      formControlName="accountType"
    />
  </mat-form-field>
</div>
<button
  (click)="saveForm()"
  mat-flat-button
  class="impr-group-button"
  i18n="save button @@buttonSave"
  color="accent"
>
  Opslaan
</button>
