<!-- @format -->

<section style="overflow-y: auto; padding-right: 15px">
  <h2
    mat-dialog-title
    i18n="add-edit-improvement-dialog dialog-title"
    *ngIf="true"
  >
    Create new questionnaire
  </h2>
  <h2
    mat-dialog-title
    i18n="add-edit-improvement-dialog dialog-title"
    *ngIf="false"
  >
    Edit questionnaire
  </h2>
  <form [formGroup]="questionnaireForm">
    <mat-form-field appearance="fill">
      <mat-label>Onderwerp</mat-label>
      <input
        type="text"
        matInput
        formControlName="title"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Beschrijving</mat-label>
      <input
        type="text"
        matInput
        formControlName="description"
      />
    </mat-form-field>
    <hr />

    <ng-container *ngFor="let questionRange of questionForm.controls">
      <div [formGroup]="questionRange">
        <mat-form-field appearance="fill">
          <mat-label>Percentage</mat-label>
          <input
            type="text"
            matInput
            formControlName="rangePercentage"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Description</mat-label>
          <input
            type="text"
            matInput
            formControlName="rangeDescription"
          />
        </mat-form-field>
      </div>
    </ng-container>
    <div>
      <button
        *ngIf="questionAddMode"
        mat-flat-button
        color="accent"
        class="no-grow filters-container"
        (click)="addRange()"
      >
        <mat-icon>add</mat-icon>
        Add range
      </button>
      <button
        *ngIf="questionAddMode"
        mat-flat-button
        color="accent"
        class="no-grow filters-container"
        (click)="saveQuestion()"
      >
        <mat-icon>add</mat-icon>
        Save question
      </button>
      <button
        *ngIf="!questionAddMode"
        mat-flat-button
        color="accent"
        class="no-grow filters-container"
        (click)="addQuestion()"
      >
        <mat-icon>add</mat-icon>
        Add question
      </button>
    </div>
  </form>
  <mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      (click)="closeDialog()"
      i18n="cancel button @@buttonCancel"
    >
      Annuleren
    </button>
    <button
      mat-flat-button
      color="accent"
      (click)="closeDialog()"
      [disabled]="!questionnaireForm.valid"
      i18n="save button @@buttonSave"
    >
      Opslaan
    </button>
  </mat-dialog-actions>
</section>
